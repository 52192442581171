import { Directive, ElementRef, inject } from '@angular/core';

@Directive({
    selector: '[stDropdownItem]'
})
export class DropdownItemDirective {
    el = inject(ElementRef);

    constructor() {
        this.el.nativeElement.classList.add(
            'px-5',
            'py-2',
            'hover:text-white',
            'cursor-pointer'
        );
    }
}
